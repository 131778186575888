.ag-theme-alpine {
  /* Borders */
  --ag-borders: none;
  --ag-border-color: var(--color-border);
  --ag-odd-row-background-color: #fff;
  --ag-row-border-color: var(--color-border);
  --ag-borders-secondary: solid 1px;

  /* Hover & selected state */
  --ag-row-hover-color: var(--color-grid-hover);
  --ag-selected-row-background-color: var(--color-grid-selected);
  --ag-range-selection-background-color: var(--color-grid-selected);

  /* Header */
  --ag-header-foreground-color: #666666;
  --ag-header-background-color: #fff;
  --ag-foreground-color: #424242;

  /* Font */
  --ag-font-weight: unset;
  -moz-osx-font-smoothing: grayscale;

  /* Popup */
  --ag-popup-shadow: none;

  --ag-value-change-value-highlight-background-color: var(--color-grid-hover);
}

.ag-cell-data-changed-animation {
  background-color: transparent;
  transition: background-color 500ms !important;
  -webkit-transition: background-color 500ms !important;
  -moz-transition: background-color 500ms !important;
  -o-transition: background-color 500ms !important;
}

.pinnedHeaderShadowEffect {
  position: relative;
  z-index: 1;
  box-shadow: 0px 16px 32px 0px rgba(66, 66, 66, 0.08),
    0px 12px 24px 0px rgba(66, 66, 66, 0.12),
    0px 4px 8px 0px rgba(66, 66, 66, 0.12),
    0px 2px 4px 0px rgba(66, 66, 66, 0.12);
}

.pinnedColumnsShadowEffect {
  position: relative;
  z-index: 1;
  box-shadow: 0px -5px 32px 0px rgba(66, 66, 66, 0.08),
    0px -5px 24px 0px rgba(66, 66, 66, 0.12),
    0px -5px 8px 0px rgba(66, 66, 66, 0.12),
    0px -5px 4px 0px rgba(66, 66, 66, 0.12);
}

/* Header cell formatting */
.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid var(--color-border);
  padding: 0px 0px !important;
}

/* Header cell text */
.ag-theme-alpine .ag-header-cell-text {
  color: var(--color-content-strong) !important;
  padding: 0px var(--spacing-p50) !important;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  -webkit-font-smoothing: antialiased !important;
}

/* Borders between header cells */
.ag-theme-alpine .ag-cell:not(.ag-cell-inline-editing) {
  display: flex;
  padding: var(--spacing-p50) !important;
  align-items: start !important;
  border: none !important;
}

.ag-cell-inline-editing {
  background-color: red !important;
  height: 100% !important;
  padding: 0px !important;
  border: none !important;
}

.ag-popup-editor.ag-ltr.ag-popup-child {
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
}

/* AG Grid shifts the hovered cell over by 1px, we do not want that */
.ag-cell-focus > .ag-cell-wrapper {
  margin-left: 0px !important;
}

.ag-theme-alpine .ag-cell-wrapper {
  width: 100%;
}

/* Remove header resize visual */
.ag-theme-alpine .ag-header-cell-resize::after {
  width: 0px !important;
}

/* No border around the grid */
.ag-theme-alpine .ag-root-wrapper {
  border: none !important;
}

.ag-theme-alpine .ag-root {
  padding-left: var(--spacing-p50) !important;
}
/* Borders between row cells */
.ag-theme-alpine .ag-ltr .ag-cell {
  border-right: 1px solid var(--color-border) !important;
}

/* Make right-cols-container background transparent */
.ag-theme-alpine .ag-pinned-right-cols-container .ag-row-hover,
.ag-theme-alpine .ag-pinned-right-cols-container .ag-row-focus {
  --ag-row-hover-color: transparent;
  background-color: var(--ag-row-hover-color) !important;
}

/* Remove blue background color when row is hovered and selected */
.ag-theme-alpine .ag-row-hover.ag-row-selected::before {
  background-image: none !important;
}

/* Chips need to be darker when within a hovered row due to the hover to add more contrast with the row hover color */
.ag-theme-alpine .ag-row-hover .row-hover-chip-background {
  background-color: #ededed !important;
}

.ag-pinned-left-header {
  border-right: none;
}

.ag-header,
.ag-advanced-filter-header {
  background-color: transparent;
  border-bottom: 1px solid var(--color-border);
}
