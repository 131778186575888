html {
  overscroll-behavior-x: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-x: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Removing certain browsers default black outlines on raw input */
*,
*:focus,
*:hover {
  outline: none;
}

.ag-cell-wrapper {
  overflow: hidden;
}

.simple-menu {
  font-size: 10pt;
  margin: 0 0 0 150px;
}

.menu-item {
  display: flex;
  align-items: center;
}

.menu-item-icon {
  position: relative;
  top: -2px;
  line-height: 10px;
  margin-right: 5px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-modal {
  background-color: white;
  width: 50%;
  height: 70%;
  overflow: auto;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 20px;
}

.add-feed-modal {
  width: 350px !important;
}

.add-feed-modal-input {
  width: 300px;
  margin-bottom: 15px !important;
}

.filter-input-autocomplete-popover {
  padding: 15px;
}

.extra-grid-actions-popover {
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 15px;
}

.typography {
  font-weight: bold;
  margin-left: 10px;
}

.close-button {
  float: right;
  margin: 0 0 10px 10px;
  position: relative;
  bottom: 28px;
}

.ag-react-container {
  width: 100%;
  height: 100%;
}

/* Common global styles. Later it can be moved to separate file. */
.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ag-header-cell .ag-react-container {
  height: 100%;
}

.hidden {
  display: none;
}

.transition-ease-in-out {
  transition: 0.3s all ease-in-out;
}
.visibility-hidden {
  visibility: hidden;
  opacity: 0;
}
.visibility-show {
  opacity: 1;
  visibility: visible;
}

.underline {
  text-decoration: underline;
}

.justify-between {
  justify-content: space-between;
}

.pointer-none {
  pointer-events: none;
}

.light-bottom-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* width */
.low-profile-scrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.low-profile-scrollbar::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
.low-profile-scrollbar::-webkit-scrollbar-thumb {
  background: #e1e2e3;
  border-radius: 10px;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scrollbar-thin {
  scrollbar-width: thin;
}

.cta-gradient {
  background: linear-gradient(98.31deg, #ff7f36 15.92%, #ffc700 80.79%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.cta-gradient-wrapper {
  background: linear-gradient(91.77deg, #fe5d45 0.1%, #fac300 100%);
}
.cta-gradient-container {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  background: white;
  margin-top: 1px;
  margin-bottom: 1px;
}

.onboarding-loading-gradient-animation {
  background: linear-gradient(91.77deg, #fe5d45 0%, #fac300 50%, #fe5d45 100%);
  background-size: 200% 100%;
  animation: gradientAnimation 2s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}

.onboarding-loading-width-animation {
  animation: widthAnimation 10s normal forwards ease-in-out;
}

@keyframes widthAnimation {
  0% {
    width: 0%;
  }
  100% {
    width: 300px;
  }
}

.fade-in {
  opacity: 0; /* Initially hidden */
  animation: fadeIn 1s forwards; /* Animation duration and fill mode */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-move-up {
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Initially moved down by 20px */
  animation: fadeMoveUp 4s forwards; /* Animation duration and fill mode */
}

@keyframes fadeMoveUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  35% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  65% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

:root {
  --color-text: #101419;
  --color-white: #fff;
  --color-harmonic-orangered: #fe5000;
  --color-harmonic-green: #06351e;
  --color-text-grey: #7f7f7f;
  --color-text-dark: #000000;
  --color-green-dark: #00a86b;
  --color-green-light: #4cd571;
  --color-red-dark: #e12317;
  --color-cyan-dark: #0c87bc;
  --color-teal-base: #1dc5c0;
  --color-green: #75eac9;
  --color-orange: #f2d44e;
  --color-yellow-dark: #f6a500;
  --color-yellow: #f7b751;
  --color-pink: #de3aab;
  --color-light-pink: #da565833;
  --color-blue: #4392f6;
  --color-hover-blue: #ebf5ff;
  --color-dark-blue: #0078d4;
  --color-grey: #ebecef;
  --color-light-grey: #fafafa;
  --color-dark-grey: #838383;
  --color-empty-grey: #cccbca;
  --color-primary: #303f9f;
  --color-border: #e9ebee;
  --color-chip-grey: #f9f9fa;
  --color-border-grey: #eceef0;
  --color-grid-hover: #16295005;
  --color-grid-selected: #4b78d20a;
  --color-background-grey: #f8f8f8;
  --color-red: #ff9d9d;
  --color-docs-primary-dark: #0b6be9;
  --color-docs-gray-dark: #889baa;
  --color-docs-ink-lighter: #5a5e61;
  --color-docs-ink-lightest: #7a7e80;
  --color-docs-sky-lighter: #f4f4f4;
  --color-docs-sky-dark: #b4b6b8;
  --color-docs-dark-mode-darker: #1b2036;
  --color-docs-dark-mode-light: #506fa2;
  --color-docs-dark-mode-base: #274270;
  --color-docs-code-panel-dark: #2f3c4d;
  --color-docs-code-panel-semidark: #404f62;
  --color-table-border: rgba(0, 0, 0, 0.12);
  --color-line-light: #e3e5e6;
  --color-content-header-gray: #939393;
  --dropdown-icon-color: rgba(0, 0, 0, 0.54);
  --box-shadow: 0 1.7rem 2.4rem 0 rgba(0, 0, 0, 0.06);
  --box-shadow-light: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.03);
  --box-shadow-dark: 0 0.5rem 2rem 0 rgba(0, 0, 0, 0.09);
}
