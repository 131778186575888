/* Hide right header and spacer */
.ag-pinned-right-header,
.ag-horizontal-right-spacer {
  width: 0 !important;
  min-width: 0 !important;
}

/* Add absolute position so that action buttons column will appear on top of other columns. pointer-events: none to pass on mousemove event to behind columns */
.ag-pinned-right-cols-container {
  position: absolute !important;
  right: 0;
  pointer-events: none;
}

/* Reset pointer-events so that click can happen on action buttons */
.reset-pointer-events * {
  pointer-events: initial;
}

/* Hide border of right-cols-container */
.ag-theme-alpine .ag-ltr .ag-pinned-right-cols-container .ag-cell {
  border: none !important;
  border-right: none !important;
}

/* Show action buttons only for row that is being hovered. For rows which are not being hovered, hide them by setting their width and padding to 0.*/
.ag-pinned-right-cols-container .ag-row:not(.ag-row-focus),
.ag-pinned-right-cols-container .ag-row:not(.ag-row-focus) .ag-cell {
  width: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  background-color: transparent !important;
}

.ag-theme-alpine .ag-row .hover-entity-actions {
  margin-bottom: 34px;
  display: flex;
  justify-content: flex-end;
}

.ag-cell-first-right-pinned {
  justify-content: end;
}

/* Entire row should be gray if cell is active */
.ag-theme-alpine .ag-row-focus {
  background: #fafafa !important;
}

.ag-cell-focus {
  background: #f5f5f5 !important;
  border: none !important;
}

.ag-cell-focus > .ag-cell-wrapper {
  margin-left: 1px;
}
